
const GroupRaffleResultGroupMember = ({member}) => {
  return (  
    <tr>
      <td>
        {member}
      </td>
    </tr>
  );
}
 
export default GroupRaffleResultGroupMember;